import styled, { css } from "styled-components";

import back from "../../../assets/MegaMenu/Back.svg";
import close from "../../../assets/MegaMenu/Close.svg";
import menu from "../../../assets/MegaMenu/Menu.svg";
import greenBg from "../../../assets/MegaMenu/MobileGreenBG.png";
import scribble from "../../../assets/MegaMenu/MobileScribble.svg";
import whiteBg from "../../../assets/MegaMenu/WhiteMobileBG.svg";
import whiteTabletBg from "../../../assets/MegaMenu/WhiteTabletBG.svg";
import { StyledButton } from "../../Button/partials";
import { MMLabel } from "./StyledMegaMenu";

interface Props {
  isOpen?: boolean;
  scrolled?: boolean;
  showDivider?: boolean;
  fixed?: boolean;
}

export const MobileMMWrapper = styled.nav<Props>`
  position: ${({ fixed }) => (fixed ? "fixed" : "absolute")};
  z-index: 9999;
  width: 100vw;
  padding: 0 ${({ theme }) => theme.spacing.three} ${({ theme, isOpen }) => isOpen && theme.spacing.seven};
  background: ${({ isOpen, theme }) =>
    isOpen
      ? css`no-repeat url(${scribble}), no-repeat url(${whiteTabletBg}), no-repeat url(${greenBg}), white`
      : theme.colors.neutrals.white};
  background-position: bottom 245px right, bottom 280px center, bottom;
  background-size: auto, cover, contain;
  box-shadow: ${({ scrolled, isOpen }) => (scrolled || isOpen) && "rgba(25, 25, 25, 0.4) 0px 16px 16px"};

  ${({ showDivider }) =>
    showDivider &&
    css`
      ::before {
        position: absolute;
        content: "";
        width: 100vw;
        height: 1px;
        background: rgba(25, 25, 25, 0.2);
        top: 118px;
        left: 0;
      }
    `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      ::after {
        position: absolute;
        content: "";
        width: 100vw;
        height: 1px;
        background: rgba(25, 25, 25, 0.2);
        bottom: 176px;
        left: 0;
      }
    `};

  @media screen and (max-width: 370px) {
    background-size: auto, cover, cover;
    background-position: bottom 245px right, bottom 280px center, bottom -375px center;
  }

  @media screen and (min-width: 371px) and (max-width: ${({ theme }) => theme.breakpoints.s}) {
    background-size: auto, contain, contain;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.m}) and (max-width: 960px) {
    background: ${({ isOpen, theme }) =>
    isOpen
      ? css`no-repeat url(${scribble}), no-repeat url(${whiteTabletBg}), no-repeat url(${greenBg}), white`
      : theme.colors.neutrals.white};
    background-position: bottom 245px right, bottom 280px center, bottom;
    background-size: auto, cover, contain;
  }

  @media screen and (min-width: 961px) {
    display: none;
  }
`;

export const MobileMMStickyItems = styled.div`
  padding: ${({ theme }) => theme.spacing.three} 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MobileMMLogoWrapper = styled.a`
  cursor: pointer;

  img {
    width: 136px;
  }
`;

export const MobileMMOpenCloseIcon = styled.div<Props>`
  width: 16px;
  height: 16px;
  background: no-repeat url(${({ isOpen }) => (isOpen ? close : menu)});
  background-size: contain;
  cursor: pointer;
`;

export const MobileMMTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: ${({ theme }) => theme.spacing.two} 0;
`;

export const MobileMMSwiperSlide = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileMMBackButtonWrapper = styled(MMLabel)`
  padding-left: 22px;
  background: no-repeat url(${back}) center left;
  margin-bottom: 14px;
`;

export const MobileButtonContainer = styled.div`
  width: fit-content;
  margin-bottom: ${({ theme }) => theme.spacing.four};
`;

export const MobileMMBottomInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.four};
  margin-top: 100px;

  a {
    width: fit-content;
    height: fit-content;
  }

  ${StyledButton} {
    font-size: 14px;
    min-height: 40px;
    padding: 12px 20px;
    background: transparent;
    color: ${({ theme }) => theme.colors.neutrals.white};
    border: 1px solid ${({ theme }) => theme.colors.neutrals.white};
  }
`;

export const MobileMMContactNumberWrapper = styled.a`
  display: flex;
  cursor: pointer;
  align-items: center;

  :hover {
    text-decoration: none;
  }
`;
