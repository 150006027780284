import React, { useEffect } from "react";

// Polyfill for smooth scroll to fix safari, edge and IE
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import smoothscroll from "smoothscroll-polyfill";
import { ThemeProvider } from "styled-components";

import { Banner } from "../components/Banner";
import { Footer } from "../components/Footer";
import { GlobalNavigation } from "../components/GlobalNavigation";
import { MegaMenu } from "../components/MegaMenu/MegaMenu";
import SettingsContextHooksProvider, { ISettings } from "../context/SettingsContextHooks";
import { GlobalStyle } from "../styles/globalStyle";
import "../styles/main.scss";
import { theme } from "../styles/theme";
import { StyledMain, StyledSite } from "./partials";

interface ILayoutProps {
  children?: React.ReactNode;
  settings?: ISettings;
  alternatives?: Array<string>;
}

const Layout: React.FC<ILayoutProps> = ({ children, settings = {}, alternatives = [] }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      smoothscroll.polyfill();
    }
  }, []);

  return (
    <>
      <SettingsContextHooksProvider settings={settings}>
        <ThemeProvider theme={theme}>
          <StyledMain>
            <GlobalStyle />
            <Banner />
            <StyledSite id="site" banner={settings?.content?.banner_notice ? true : false}>
              {settings?.content?.disableGlobal ? <MegaMenu /> : <GlobalNavigation alternatives={alternatives} />}
              {children}
            </StyledSite>
          </StyledMain>
        </ThemeProvider>
      </SettingsContextHooksProvider>
    </>
  );
};

export default Layout;
