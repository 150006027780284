import styled, { css } from "styled-components";

interface GlobalNavigationProps {
  readonly isOpen: boolean;
  readonly maxHeight?: string;
}

interface GlobalNavItems {
  readonly isActive?: boolean;
}

export const StyledGlobalNavigationModal = styled.header<GlobalNavigationProps>`
  position: relative;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    position: ${(props) => (props.isOpen ? "fixed" : "absolute")};
    display: block;
    z-index: 8000; /* Sit on top */
    left: 0;
    top: 0px;
    width: 100%; /* Full width */
    min-height: 70px;
    height: ${(props) => (props.isOpen ? "100%" : "auto")};
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
`;

export const StyledGlobalNavigation = styled.nav<GlobalNavigationProps>`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.neutrals.white};
  height: auto;
  width: 100%;
  z-index: 8001;

  ${({ isOpen }) =>
    isOpen &&
    css`
      background: linear-gradient(
        90deg,
        #ffffff 50%,
        ${({ theme }) => theme.colors.neutrals.sisal20} 50%
      );
    `}

  transition: max-height 0.2s linear;
  -moz-transition: max-height 0.2s linear;
  -webkit-transition: max-height 0.2s linear;
  -o-transition: max-height 0.2s linear;
  max-height: ${(props) => (props.isOpen ? props.maxHeight : "100px")};
  overflow: hidden;
  box-shadow: ${({ isOpen }) =>
    isOpen ? "0px 16px 16px rgba(25, 25, 25, 0.16)" : "initial"};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow-y: ${({ isOpen }) => (isOpen ? "scroll" : "none")};
    background: #ffffff;
    overflow-x: none;
    max-height: ${(props) => (props.isOpen ? "calc(95vh - 80px)" : "70px")};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 0 ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 0;
  }
`;

export const StyledGlobalNavigationInner = styled.div<GlobalNavigationProps>`
  position: relative;
  margin: 0 auto;
  max-width: 960px;

  ${({ isOpen }) =>
    isOpen &&
    css`
      background: linear-gradient(
        90deg,
        #ffffff 65%,
        ${({ theme }) => theme.colors.neutrals.sisal20} 65%
      );
    `}
`;

export const StyledHeaderLogo = styled.a`
  position: relative;
  height: auto;
  width: auto;
  max-width: 150px;

  & img {
    height: 32px;
    object-fit: contain;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    & img {
      height: 24px;
      object-position: left;
    }
  }
`;

export const StyledHeaderContainerClosed = styled.div<GlobalNavigationProps>`
  position: relative;
  display: flex;
  height: auto;
  width: 100%;
  margin: 0 auto;

  max-width: ${(props) => props.theme.maxWidth};
  padding: ${(props) => props.theme.spacing.four} 0;
  align-items: center;
  justify-content: space-between;
  z-index: 5;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 20px ${(props) => props.theme.spacing.three};
    background: #ffffff;
  }
`;

export const StyledHeaderContainerOpen = styled.div<GlobalNavigationProps>`
  width: 100%;

  margin-top: ${({ isOpen }) => (isOpen ? "0px" : "-10px")};
  transition: margin-top 0.2s linear;
  -moz-transition: margin-top 0.2s linear;
  -webkit-transition: margin-top 0.2s linear;
  -o-transition: margin-top 0.2s linear;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    height: fit-content;
  }
`;

export const StyledHeaderContainerOpenWrapper = styled.div<GlobalNavigationProps>`
  position: relative;
  display: block;
  margin: 0 auto;

  max-width: ${(props) => props.theme.maxWidth};

  @media screen and (max-width: ${(props) => props.theme.maxWidth}) {
    padding: 0;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 0;
    background: #ffffff;
  }
`;

export const StyledHorizontalNav = styled.nav`
  & ul {
    display: grid;
    grid-gap: ${(props) => props.theme.spacing.five};
    grid-auto-flow: column;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;

    > div {
      & a {
        margin-left: 16px;
      }
    }

    @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
      > div {
        display: none;
      }
    }
  }

  & li {
    float: left;
  }
`;

export const StyledMenu = styled.div`
  z-index: 5;
  cursor: pointer;
  display: flex;
  align-items: center;
  & label {
    font-size: 14px;
    cursor: pointer;
  }

  & svg {
    margin: 0 ${(props) => props.theme.spacing.two} 0 0;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    & label {
      display: none;
    }

    & svg {
      margin: 0;
    }
  }
`;

export const StyledHeaderContainerOpenNav = styled.div`
  display: flex;
  justify-content: space-between;
  height: auto;
  width: 100%;
  padding: ${(props) => props.theme.spacing.four} 0 0 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: grid;
    grid-template-columns: 100%;
    grid-auto-flow: row;
    grid-template-rows: auto auto;
  }
`;

export const StyledHeaderContainerOpenNavMain = styled.div`
  width: 600px;
  & ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: ${(props) => props.theme.spacing.two};

    @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
      grid-template-columns: 100%;
      grid-gap: ${(props) => props.theme.spacing.three};
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 0 ${(props) => props.theme.spacing.four};
  }
`;

export const StyledHeaderContainerOpenNavMainItem = styled.li<GlobalNavItems>`
  display: flex;
  align-items: center;
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};

  & a {
    font-size: 14px;
    cursor: pointer;
    display: flex;
    flex-flow: row;
    align-items: center;

    & svg {
      opacity: 0;
      transition: transform 500ms;
    }

    :hover {
      text-decoration: none;
      & svg {
        opacity: 1;
        transform: translateX(16px);
      }
    }

    :lang(ar) {
      & svg {
        transform: rotate(180deg);
        margin-right: ${({ theme }) => theme.spacing.one};
      }

      :hover {
        & svg {
          transform: rotate(180deg) translateX(16px);
        }
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;

    & a {
      font-size: 16px;
    }
  }
`;

export const StyledHeaderContainerOpenNavMainTitle = styled.a`
  font-size: ${(props) => props.theme.fontSize.l};
  font-weight: bold;
  display: flex;
  flex-flow: row;
  align-items: center;

  margin-bottom: ${(props) => props.theme.spacing.four};

  & svg {
    opacity: 0;
    transition: transform 500ms;
  }

  :hover {
    & svg {
      opacity: 1;
      transform: translateX(16px);
    }
  }

  :lang(ar) {
    & svg {
      transform: rotate(180deg);
      margin-right: ${({ theme }) => theme.spacing.one};
    }

    :hover {
      & svg {
        transform: rotate(180deg) translateX(16px);
      }
    }
  }
`;

export const StyledHeaderContainerOpenNavIcon = styled.div`
  height: 40px;
  width: 40px;
  margin-right: ${(props) => props.theme.spacing.two};
  float: left;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    height: 32px;
    width: 32px;
  }
`;

export const StyledMobileGraphicContainer = styled.div`
  display: none;
  background-color: ${(props) => props.theme.colors.neutrals.sisal20};

  margin-top: 20px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: block;
    max-height: 73px;
    width: 100%;
    overflow: hidden;

    & img {
      object-fit: cover;
      position: absolute;
      right: 0;
    }

    & svg {
      display: block;
    }
  }
`;

export const StyledHeaderContainerOpenNavAside = styled.div`
  display: block;
  position: relative;
  height: auto;
  width: auto;
  min-width: 160px;
  margin-top: auto;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    background-color: ${(props) => props.theme.colors.neutrals.sisal20};
    margin: 0;
    padding-bottom: ${(props) => props.theme.spacing.two};
  }
`;

export const StyledVerticalNav = styled.ul`
  width: 100%;

  text-align: right;
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${(props) => props.theme.spacing.one};
  border-left: 1px solid ${(props) => props.theme.colors.neutrals.coolGrey};
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    position: relative;
    text-align: left;
    overflow-y: auto;
    border: none;
  }
`;

export const StyledVerticalNavItem = styled.li<GlobalNavItems>`
  border-left: ${(props) =>
    props.isActive ? `4px solid ${props.theme.colors.neutrals.black}` : "none"};
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};

  :hover {
    border-left: ${(props) => `4px solid ${props.theme.colors.neutrals.black}`};
  }

  & a {
    font-size: 14px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    border-left: ${(props) =>
      props.isActive
        ? `4px solid ${props.theme.colors.neutrals.black};`
        : `4px solid ${props.theme.colors.neutrals.white};`};
    & a {
      font-size: 16px;
      margin-left: ${(props) => props.theme.spacing.four};
    }

    padding: ${(props) => props.theme.spacing.two} 0;
  }
`;

export const StyledHeaderContainerOpenContact = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0
    ${(props) => props.theme.spacing.three} 0;

  display: flex;
  justify-content: space-between;
  width: 100%;

  & select {
    background-color: ${(props) => props.theme.colors.neutrals.sisal20};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: grid;
    grid-gap: ${(props) => props.theme.spacing.four};
    grid-auto-flow: row;
    background-color: ${(props) => props.theme.colors.neutrals.sisal20};

    border-top: 1px solid ${(props) => props.theme.colors.neutrals.coolGrey};
    width: 100%;
    padding: ${(props) => props.theme.spacing.four};
  }
`;

export const StyledHeaderContactNumber = styled.a`
  display: grid;
  grid-auto-flow: column;
  column-gap: ${(props) => props.theme.spacing.two};
  align-items: center;

  font-size: ${(props) => props.theme.fontSize.s};
  color: ${(props) => props.theme.colors.neutrals.black};

  & svg {
    height: 16px;
    width: 16px;
  }
`;

export const StyledHeaderMobileCTA = styled.a`
  display: none;
  height: 50px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.warmths.heritage};
  padding: 0 ${(props) => props.theme.spacing.three};
  color: ${(props) => props.theme.colors.neutrals.white};

  :hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.neutrals.white};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const StyledDesktopImg = styled.img<GlobalNavigationProps>`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: absolute;
  top: 0;
  right: 240px;
  height: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`;
