import styled, { css } from "styled-components";

import arrow from "../../../assets/MegaMenu/ArrowRight.svg";
import arrowLarge from "../../../assets/MegaMenu/ArrowRightLarge.svg";
import SubItemBg from "../../../assets/MegaMenu/SubItemBG.svg";
import { MMLabel } from "./StyledMegaMenu";

interface Props {
  hasIcon?: boolean;
  column?: "left" | "right";
  row?: number;
  index?: number;
  isActive?: boolean;
}

export const StyledSubItemArrowWrapper = styled.div`
  width: 30px;
  height: 14px;
  display: flex;
  background: no-repeat url(${arrow});
  background-position: center;
`;

export const StyledSubItem = styled.div<Props>`
  grid-column: 1 / span ${({ hasIcon }) => (hasIcon ? 4 : 3)};
  grid-row: auto;
  display: flex;
  align-items: center;
  text-align: left;
  gap: ${({ theme }) => theme.spacing.three};
  position: relative;
  z-index: 5;
  height: 100%;

  img {
    width: 32px;
    height: 32px;
    object-fit: contain;
  }

  :hover {
    cursor: pointer;

    a {
      font-weight: 700;
    }

    ${StyledSubItemArrowWrapper} {
      background: no-repeat url(${arrowLarge});
    }

    ${MMLabel} {
      max-width: calc(100% - 48px);
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      ::before {
        position: absolute;
        width: calc(100% + 50px);
        height: 100%;
        left: -12px;
        top: 0;
        content: "";
        border-radius: 16px;
        background: no-repeat url(${SubItemBg});
        z-index: -1;
      }
    `}
`;

export const StyledSubItemContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.one};

  ${MMLabel} {
    max-width: calc(100% - 60px);
  }

  @media screen and (max-width: 960px) {
    grid-column: 1 / span 4;
    grid-row: auto;
    margin: ${({ theme }) => theme.spacing.two} 0;
  }
`;

export const StyledSubItemLinkOnlyWrapper = styled.div<Props>`
  grid-column: ${({ index }) => index !== undefined && (index <= 3 ? 1 : index > 3 && index <= 7 ? 4 : 7)} / span 3;
  grid-row: ${({ row }) => row};
  align-self: center;
`;

export const StyledSubItemListItemWrapper = styled.div<Props>`
  grid-column: ${({ column, hasIcon }) => (column === "left" ? (hasIcon ? 5 : 4) : hasIcon ? 8 : 7)} / span 3;
  grid-row: ${({ row }) => row};
  align-self: center;
  z-index: 10;
`;

export const StyledSubItemListItemBackground = styled.div<Props>`
  grid-column: ${({ hasIcon }) => (hasIcon ? 5 : 4)} / span ${({ hasIcon }) => (hasIcon ? 8 : 9)};
  grid-row: 3 / span ${({ row }) => row && row + 2};
  position: relative;
  height: 100%;

  ::before {
    position: absolute;
    width: calc(100% + 24px);
    height: calc(100% + 28px);
    top: -28px;
    left: -24px;
    content: "";
    border-top-left-radius: 80px;
    background: ${({ theme }) => theme.colors.neutrals.offwhite};
    z-index: 1;
  }
`;
