export const columnPositions = (idx: number, count: number) => {
  if (count <= 8) return idx <= 3 ? "left" : "right";
  else if (count === 9 || count === 10) return idx <= 4 ? "left" : "right";
  else return idx <= 5 ? "left" : "right";
};

export const linkOnlyRowPosition = (index: number) => {
  if (index === 0 || index === 4 || index === 8) return 3;
  else if (index === 1 || index === 5 || index === 9) return 4;
  else if (index === 2 || index === 6 || index === 10) return 5;
  else return 6;
};

export const rowPosition = (index: number, count: number) => {
  if (count <= 8) {
    if (index === 0 || index === 4) return 3;
    if (index === 1 || index === 5) return 4;
    if (index === 2 || index === 6) return 5;
    if (index === 3 || index === 7) return 6;
  } else if (count === 9 || count === 10) {
    if (index === 0 || index === 5) return 3;
    if (index === 1 || index === 6) return 4;
    if (index === 2 || index === 7) return 5;
    if (index === 3 || index === 8) return 6;
    if (index === 4 || index === 9) return 7;
  } else {
    if (index === 0 || index === 6) return 3;
    if (index === 1 || index === 7) return 4;
    if (index === 2 || index === 8) return 5;
    if (index === 3 || index === 9) return 6;
    if (index === 4 || index === 10) return 7;
    if (index === 5 || index === 11) return 8;
  }
};
