import styled, { css } from "styled-components";

import greenBg from "../../../assets/MegaMenu/GreenBG.png";
import phone from "../../../assets/MegaMenu/Phone.svg";
import scribble from "../../../assets/MegaMenu/Scribble.svg";
import chevron from "../../../assets/MegaMenu/UpChevron.svg";
import { StyledButton } from "../../Button/partials";

interface Props {
  isActive?: boolean;
  hasIcon?: boolean;
  rows?: number;
  isOpen?: boolean;
  windowWidth?: number;
  scrolled?: boolean;
}

export const MegaMenuBackgroundWrapper = styled.div<Props>`
  position: fixed;
  z-index: 9999;
  width: 100vw;
  min-height: 80px;
  background: white;
  box-shadow: ${({ scrolled }) => scrolled && "rgba(25, 25, 25, 0.16) 0px 16px 16px"};

  ${({ isOpen, windowWidth }) =>
    isOpen &&
    css`
      ::before {
        position: absolute;
        content: "";
        height: 100%;
        width: calc(174px + (${windowWidth}px / 2));
        background: no-repeat url(${greenBg});
        background-position: top left;
        left: calc(786px + ((${windowWidth}px - ${({ theme }) => theme.maxWidth}) / 2));
        z-index: 6;
      }

      ::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 128px;
        background: no-repeat url(${scribble});
        left: calc(705px + ((${windowWidth}px - ${({ theme }) => theme.maxWidth}) / 2));
        top: 70px;
        z-index: 7;
      }
    `}

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const MegaMenuWrapper = styled.nav<Props>`
  height: 100%;
  max-width: ${({ theme }) => theme.maxWidthWPadding};
  margin: auto;
  padding: 0 ${({ theme }) => theme.spacing.three};
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: ${({ isOpen, rows }) => (isOpen ? `80px auto repeat(${rows}, 56px) auto 1fr` : "80px")};
  column-gap: ${({ theme }) => theme.spacing.three};
  align-items: center;
`;

export const MegaMenuLogoWrapper = styled.a`
  grid-column: 1 / span 2;
  grid-row: 1;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  img {
    width: 100%;
  }
`;

export const MegaMenuTopLevelItems = styled.div<Props>`
  grid-column: 4 / span 10;
  grid-row: 1;
  display: flex;
  justify-content: space-between;
  z-index: 7;

  ${StyledButton} {
    font-size: 12px;
    min-height: ${({ theme }) => theme.spacing.four};
    height: ${({ theme }) => theme.spacing.four};
    padding: 8px 16px;
    background: transparent;
    color: ${({ isOpen }) => (isOpen ? "white" : "black")};
    border: 1px solid ${({ isOpen }) => (isOpen ? "white" : "black")};
  }
`;

export const MegaMenuContactNumberWrapper = styled.a<Props>`
  grid-column: 11 / span 3;
  grid-row: 2;
  z-index: 13;
  display: flex;
  justify-content: flex-end;
  align-self: flex-start;
  cursor: pointer;
  margin-left: 13px;

  :hover {
    text-decoration: none;
  }
`;

export const MegaMenuContactNumber = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: white;

  @media screen and (max-width: 960px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

export const MegaMenuPhoneIcon = styled.div`
  width: 14px;
  height: 14px;
  margin-right: ${({ theme }) => theme.spacing.two};
  background: no-repeat url(${phone});
  background-size: contain;
`;

export const MegaMenuNavItemsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.three};
  align-items: center;
`;

export const MMLabel = styled.a<Props>`
  cursor: pointer;
  font-size: 14px;
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
  line-height: 24px;
  margin: 0;
  text-align: left;

  :hover {
    font-weight: 700;
    text-decoration: none;
  }

  @media screen and (max-width: 960px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const MegaMenuExpandedTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  grid-column: span 12;
  grid-row: 2;
  margin-top: ${({ theme }) => theme.spacing.five};
`;

export const MMPaneTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  grid-column: 1 / span 12;
  grid-row: 2;
  margin: ${({ theme }) => theme.spacing.five} 0 25px;
`;

export const MMSubItem = styled.div<Props>`
  grid-column: 1 / span ${({ hasIcon }) => (hasIcon ? 4 : 3)};
  grid-row: auto;
  display: flex;
`;

export const MMCloseIcon = styled.div<Props>`
  cursor: pointer;
  grid-column: 1 / span 12;
  grid-row: ${({ rows }) => rows + 4} / span 1;
  width: 16px;
  height: 16px;
  background: no-repeat url(${chevron});
  justify-self: center;
  margin: ${({ theme }) => theme.spacing.two} 0 ${({ theme }) => theme.spacing.one};
  align-self: flex-end;
  z-index: 12;
`;

export const MegaMenuLanguageWrapper = styled.div<Props>`
  grid-column: 11 / span 2;
  grid-row: ${({ rows }) => rows + 3} / span 1;
  z-index: 16;
  justify-self: flex-end;
  height: 24px;

  svg {
    color: white;
  }

  select {
    background: none;
    color: white;
    width: auto;

    option {
      color: ${({ theme }) => theme.colors.neutrals.black};
    }
  }

  @media screen and (max-width: 960px) {
    display: flex;
    align-items: flex-end;
  }
`;
