import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyledGlobalNavigationModal,
  StyledGlobalNavigation,
  StyledGlobalNavigationInner,
  StyledHeaderLogo,
  StyledHorizontalNav,
  StyledHeaderContainerOpenNavIcon,
  StyledHeaderContainerClosed,
  StyledHeaderContainerOpen,
  StyledMenu,
  StyledVerticalNav,
  StyledVerticalNavItem,
  StyledHeaderContainerOpenNav,
  StyledHeaderContainerOpenWrapper,
  StyledHeaderContainerOpenNavMain,
  StyledHeaderContainerOpenNavMainItem,
  StyledHeaderContainerOpenNavMainTitle,
  StyledMobileGraphicContainer,
  StyledHeaderContainerOpenNavAside,
  StyledHeaderContainerOpenContact,
  StyledHeaderContactNumber,
  StyledHeaderMobileCTA,
  StyledDesktopImg,
} from "./partials";

import { getUrl } from "../utils/helpers";

import MobileGraphic from "../../assets/GlobalNavigation/mobile.png";
import DesktopGraphic from "../../assets/GlobalNavigation/desktop-megamenu-cropped.svg";

import { SettingsContextHooks } from "../../context/SettingsContextHooks";
import { Button } from "@ef-global/web-ui-react/lib/components/Button";
import IconMenu from "@ef-global/web-ui-react/lib/icons/IconMenu";
import IconClose from "@ef-global/web-ui-react/lib/icons/IconClose";
import IconPhone from "@ef-global/web-ui-react/lib/icons/IconPhone";
import IconArrowRight from "@ef-global/web-ui-react/lib/icons/IconArrowRight";

import { LanguageSelector } from "../LanguageSelector";
import Cookies from "js-cookie";

import { SbImage, sbImageService } from "hult-sb-ui";

export const GlobalNavigation: React.FC<{ alternatives?: string[] }> = ({
  alternatives,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [windowSize, setWindowSize] = useState<undefined | number>(undefined);
  const [height, setHeight] = useState<string>("100px");
  const { settings } = useContext(SettingsContextHooks);
  const navRef = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      //  Set initial window size
      setWindowSize(window.innerWidth);

      if (navRef) {
        setHeight(`${navRef?.current?.scrollHeight + 10}px`);
      }

      // timeoutId for debounce mechanism
      let timeoutId: any = null;
      let heightTimeoutId: any = null;
      const resizeListener = () => {
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);
        // change width from the state object after 150 milliseconds
        timeoutId = setTimeout(() => setWindowSize(window.innerWidth), 150);
        heightTimeoutId = setTimeout(
          () => setHeight(`${navRef?.current?.scrollHeight + 10}px`),
          200
        );
      };
      // set resize listener
      window.addEventListener("resize", resizeListener);

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener("resize", resizeListener);
      };
    }
  }, []);

  function toggleMenu() {
    setIsOpen(!isOpen);
  }

  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const menuLabel = settings?.content?.menu_label
    ? settings?.content?.menu_label
    : "Menu";
  const closeLabel = settings?.content?.close_label
    ? settings?.content?.close_label
    : "Close";

  let body = null;
  if (typeof document !== "undefined") {
    body = document.querySelector("body");
  }

  if (isOpen && body && windowSize && windowSize < 768) {
    body.style.overflow = "hidden";
  } else if (!isOpen && body) {
    body.style.overflow = "auto";
  }

  const countryValue = Cookies.get("uCountry");

  return (
    <StyledGlobalNavigationModal isOpen={isOpen}>
      <StyledGlobalNavigation isOpen={isOpen} ref={navRef} maxHeight={height}>
        <StyledGlobalNavigationInner isOpen={isOpen}>
          <StyledDesktopImg src={DesktopGraphic} isOpen={isOpen} />
          <StyledHeaderContainerClosed isOpen={isOpen}>
            <StyledHeaderLogo href={settings?.content?.logo_url?.url}>
              <SbImage
                image={settings?.content?.logo}
                resize="137x32"
                height="32px"
                width="137px"
              />
            </StyledHeaderLogo>
            <StyledHorizontalNav>
              <ul>
                {settings?.content &&
                  !isOpen &&
                  Array.isArray(settings?.content?.main_nav_closed) && (
                    <div>
                      {settings?.content.main_nav_closed.map((navItem) => (
                        <Button
                          key={navItem._uid}
                          as={"a"}
                          href={getUrl(navItem.link)}
                          secondary
                          small
                        >
                          <label>{navItem.name}</label>
                        </Button>
                      ))}
                    </div>
                  )}

                {!isOpen && (
                  <li>
                    <StyledMenu onClick={(_) => toggleMenu()}>
                      <IconMenu />
                      <label>{menuLabel}</label>
                    </StyledMenu>
                  </li>
                )}
                {isOpen && (
                  <li>
                    <StyledMenu onClick={(_) => toggleMenu()}>
                      <IconClose />
                      <label>{closeLabel}</label>
                    </StyledMenu>
                  </li>
                )}
              </ul>
            </StyledHorizontalNav>
          </StyledHeaderContainerClosed>
          <StyledHeaderContainerOpen isOpen={isOpen}>
            <StyledHeaderContainerOpenWrapper isOpen={isOpen}>
              <StyledHeaderContainerOpenNav>
                <StyledHeaderContainerOpenNavMain>
                  {Array.isArray(settings?.content?.main_nav_closed) && (
                    <StyledHeaderContainerOpenNavMainTitle
                      href={getUrl(settings?.content.main_nav_closed[0].link)}
                    >
                      {settings?.content.main_nav_closed[0].name}
                      <IconArrowRight />
                    </StyledHeaderContainerOpenNavMainTitle>
                  )}

                  <ul>
                    {Array.isArray(settings?.content?.main_nav_open) &&
                      settings?.content.main_nav_open.map((navItem) => (
                        <StyledHeaderContainerOpenNavMainItem
                          key={navItem._uid}
                          isActive={url.includes(navItem.link.cached_url)}
                        >
                          <StyledHeaderContainerOpenNavIcon>
                            <img
                              src={sbImageService(
                                navItem?.icon.filename,
                                undefined,
                                "40x40"
                              )}
                              alt={navItem?.icon.alt}
                              title={navItem?.icon?.title}
                              loading="lazy"
                            />
                          </StyledHeaderContainerOpenNavIcon>
                          <a href={getUrl(navItem.link)}>
                            {navItem.name}
                            <IconArrowRight />
                          </a>
                        </StyledHeaderContainerOpenNavMainItem>
                      ))}
                  </ul>
                </StyledHeaderContainerOpenNavMain>
                <StyledMobileGraphicContainer>
                  <img src={MobileGraphic} />
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 200">
                    <path
                      fill="#ffffff"
                      fillOpacity="1"
                      d="M 0 0 Q 800 220 800 0 "
                    ></path>
                  </svg>
                </StyledMobileGraphicContainer>

                <StyledHeaderContainerOpenNavAside>
                  <StyledVerticalNav>
                    {Array.isArray(settings?.content?.side_nav_open) &&
                      settings?.content.side_nav_open.map((navItem) => (
                        <>
                          {(!navItem?.country ||
                            navItem.country === countryValue) && (
                            <StyledVerticalNavItem
                              key={navItem._uid}
                              isActive={
                                url === `/${navItem.link.cached_url}/` ||
                                url === navItem.link.cached_url ||
                                url === `/${navItem.link.cached_url}`
                              }
                            >
                              <a href={getUrl(navItem.link)}>{navItem.name}</a>
                            </StyledVerticalNavItem>
                          )}
                        </>
                      ))}
                  </StyledVerticalNav>
                </StyledHeaderContainerOpenNavAside>
              </StyledHeaderContainerOpenNav>

              <StyledHeaderContainerOpenContact>
                <StyledHeaderContactNumber
                  href={`tel:${settings?.content?.contact_number}`}
                >
                  <IconPhone />
                  {settings?.content?.contact_number}
                </StyledHeaderContactNumber>
                <LanguageSelector
                  alternatives={alternatives}
                  right={windowSize ? windowSize >= 768 : false}
                />
              </StyledHeaderContainerOpenContact>
              {settings?.content?.mobile_cta && (
                <StyledHeaderMobileCTA
                  href={getUrl(settings?.content?.mobile_cta[0]?.link)}
                >
                  {settings?.content?.mobile_cta[0].name}
                  <IconArrowRight />
                </StyledHeaderMobileCTA>
              )}
            </StyledHeaderContainerOpenWrapper>
          </StyledHeaderContainerOpen>
        </StyledGlobalNavigationInner>
      </StyledGlobalNavigation>
    </StyledGlobalNavigationModal>
  );
};
