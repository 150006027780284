import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import { SettingsContextHooks } from "../../context/SettingsContextHooks";
import { ButtonHEF } from "../Button";
import { LanguageSelector } from "../LanguageSelector";
import { getUrl } from "../utils/helpers";
import sbImageService from "../utils/sbImage";
import { NavItemProps, SubItemProps } from "./model/types";
import {
  MMCloseIcon,
  MMLabel,
  MMPaneTitle,
  MegaMenuBackgroundWrapper,
  MegaMenuContactNumber,
  MegaMenuContactNumberWrapper,
  MegaMenuLanguageWrapper,
  MegaMenuLogoWrapper,
  MegaMenuNavItemsWrapper,
  MegaMenuPhoneIcon,
  MegaMenuTopLevelItems,
  MegaMenuWrapper,
} from "./styles/StyledMegaMenu";
import {
  MobileButtonContainer,
  MobileMMBackButtonWrapper,
  MobileMMBottomInfoWrapper,
  MobileMMContactNumberWrapper,
  MobileMMLogoWrapper,
  MobileMMOpenCloseIcon,
  MobileMMStickyItems,
  MobileMMSwiperSlide,
  MobileMMTitle,
  MobileMMWrapper,
} from "./styles/StyledMobileMegaMenu";
import {
  StyledSubItem,
  StyledSubItemArrowWrapper,
  StyledSubItemContentWrapper,
  StyledSubItemLinkOnlyWrapper,
  StyledSubItemListItemBackground,
  StyledSubItemListItemWrapper,
} from "./styles/StyledSubItem";
import { columnPositions, linkOnlyRowPosition, rowPosition } from "./utils";

export const MegaMenu: React.FC = () => {
  const { settings } = useContext(SettingsContextHooks);

  const [activeNavItem, setActiveNavItem] = useState<NavItemProps | undefined>();
  const [activeSubItem, setActiveSubItem] = useState<SubItemProps | undefined>(undefined);
  const [scrolled, setScrolled] = useState(false);
  const [dimensions, setDimensions] = useState<{ windowWidth: number; windowHeight: number; oversized: boolean }>();
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const navbarRef = useRef(null);
  const mobileNavbarRef = useRef(null);
  const swiperRef = useRef(null);

  const numberOfRows = () => {
    if (activeNavItem?.subItems?.some((item) => item.isLink)) return 4;
    if (activeSubItem) return Math.max(Math.ceil(activeSubItem.listItems.length / 2), 4);
    if (activeNavItem) return Math.max(Math.ceil(activeNavItem.subItems?.length / 2), 4);
  };

  const closeMenu = useCallback(() => {
    setActiveNavItem(undefined);
    setActiveSubItem(undefined);
    setIsMobileOpen(false);
    swiperRef?.current?.swiper.slideTo(0);
  }, []);

  const toggleMobileMenu = () => {
    if (isMobileOpen) closeMenu();
    if (!isMobileOpen) setIsMobileOpen(true);
  };

  const isMobile = () => window.innerWidth <= 960;

  const trackClick = (label: string) => {
    window.dataLayer.push({ event: "menu_click", item_clicked: label });
  };

  const handleNavItemClick = useCallback((index: number) => {
    const item = settings?.content?.navItems[index];

    if (item && item.subItems) {
      setActiveSubItem(activeNavItem == item ? activeSubItem : undefined);
      setActiveNavItem(item);
      setIsMobileOpen(true);
      swiperRef?.current?.swiper.slideTo(1);
    }
  }, []);

  const handleSubItemClick = useCallback(
    (index: number) => {
      const subItem = activeNavItem?.subItems[index];

      if (subItem?.isLink && subItem.link) window.location.href = getUrl(subItem.link);
      if (!subItem?.isLink && subItem?.listItems) {
        setActiveSubItem(subItem);
        swiperRef?.current?.swiper.slideTo(2);
      }
    },
    [activeNavItem]
  );

  const handleBackClick = useCallback((depth: number) => {
    swiperRef?.current?.swiper.slidePrev();
    if (depth === 1) setActiveNavItem(undefined);
    if (depth === 2) setActiveSubItem(undefined);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0 && !scrolled) setScrolled(true);
    if (window.scrollY === 0 && scrolled) setScrolled(false);

    if (!isMobile()) closeMenu();
  };

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (!isMobile() && !navbarRef?.current?.contains(target)) closeMenu();
    if (isMobile() && !mobileNavbarRef?.current?.contains(target)) closeMenu();
  };

  const handleResize = () => {
    setDimensions({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      oversized: window.innerHeight < mobileNavbarRef?.current?.offsetHeight,
    });
  };

  useEffect(() => {
    setDimensions({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      oversized: window.innerHeight < mobileNavbarRef?.current?.offsetHeight,
    });
  }, [isMobileOpen, activeNavItem, activeSubItem]);

  // Closes menu on click-away, handles resize for desktop background positioning
  useEffect(() => {
    addEventListener("mousedown", handleClickOutside);
    addEventListener("resize", handleResize);
    addEventListener("scroll", handleScroll);

    return () => {
      removeEventListener("mousedown", handleClickOutside);
      removeEventListener("resize", handleResize);
      removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  return (
    <>
      <MegaMenuBackgroundWrapper
        ref={navbarRef}
        windowWidth={dimensions?.windowWidth}
        isOpen={!!activeNavItem}
        scrolled={scrolled || !!activeNavItem}
      >
        <MegaMenuWrapper rows={numberOfRows()} isOpen={!!activeNavItem} data-testid="mega-menu">
          <MegaMenuLogoWrapper href={getUrl(settings?.content?.logo_url)}>
            <img src={sbImageService(settings?.content?.logo?.filename)} />
          </MegaMenuLogoWrapper>
          <MegaMenuTopLevelItems isOpen={!!activeNavItem}>
            <MegaMenuNavItemsWrapper>
              {settings?.content?.navItems.map((item, idx) => (
                <MMLabel
                  onClick={() => handleNavItemClick(idx)}
                  onMouseEnter={() => handleNavItemClick(idx)}
                  key={item._uid}
                  isActive={activeNavItem?.label === item.label}
                >
                  {item.label}
                </MMLabel>
              ))}
            </MegaMenuNavItemsWrapper>
            <ButtonHEF
              as="a"
              variant="primary"
              label={settings?.content?.contactLabel}
              href={getUrl(settings?.content?.contactLink)}
            />
          </MegaMenuTopLevelItems>
          {activeNavItem && (
            <>
              <MMPaneTitle>{activeNavItem.label}</MMPaneTitle>
              <MegaMenuContactNumberWrapper href={`tel:${settings?.content?.contactNumber}`}>
                <MegaMenuPhoneIcon />
                <MegaMenuContactNumber>{settings?.content?.contactNumber}</MegaMenuContactNumber>
              </MegaMenuContactNumberWrapper>
              {activeNavItem.subItems &&
                activeNavItem.subItems.map((subItem, idx) => (
                  // Uses SubItem.ts for styles
                  <React.Fragment key={subItem._uid}>
                    {!subItem.isLink ? (
                      <StyledSubItem
                        hasIcon={!!subItem.icon}
                        onClick={() => handleSubItemClick(idx)}
                        onMouseEnter={() => handleSubItemClick(idx)}
                        isActive={activeSubItem == subItem}
                      >
                        {subItem.icon.id && <img src={sbImageService(subItem.icon.filename)} />}
                        <StyledSubItemContentWrapper>
                          <MMLabel isActive={activeSubItem == subItem}>{subItem.label}</MMLabel>
                          <StyledSubItemArrowWrapper />
                        </StyledSubItemContentWrapper>
                      </StyledSubItem>
                    ) : (
                      <StyledSubItemLinkOnlyWrapper
                        index={idx}
                        row={linkOnlyRowPosition(idx)}
                        onClick={() => handleSubItemClick(idx)}
                      >
                        <MMLabel
                          href={getUrl(subItem.link)}
                          isActive={window.location.href.includes(getUrl(subItem.link))}
                          onClick={() => trackClick(subItem.label)}
                        >
                          {subItem.label}
                        </MMLabel>
                      </StyledSubItemLinkOnlyWrapper>
                    )}
                    {activeSubItem && activeSubItem == subItem && (
                      <>
                        {activeSubItem.listItems.map((listItem, idx) => (
                          <StyledSubItemListItemWrapper
                            column={columnPositions(idx, activeSubItem.listItems.length)}
                            row={rowPosition(idx, activeSubItem.listItems.length)}
                            hasIcon={!!subItem.icon}
                            key={listItem._uid}
                          >
                            <MMLabel
                              href={getUrl(listItem.link)}
                              isActive={window.location.pathname === getUrl(listItem.link)}
                              onClick={() => trackClick(listItem.label)}
                            >
                              {listItem.label}
                            </MMLabel>
                          </StyledSubItemListItemWrapper>
                        ))}
                        <StyledSubItemListItemBackground hasIcon={!!subItem.icon} row={numberOfRows()} />
                      </>
                    )}
                  </React.Fragment>
                  // End of SubItem.ts styles
                ))}
              <MegaMenuLanguageWrapper rows={numberOfRows()}>
                <LanguageSelector />
              </MegaMenuLanguageWrapper>
              <MMCloseIcon onClick={() => closeMenu()} rows={numberOfRows()} />
            </>
          )}
        </MegaMenuWrapper>
      </MegaMenuBackgroundWrapper>
      <MobileMMWrapper
        scrolled={scrolled}
        showDivider={!!activeNavItem}
        isOpen={isMobileOpen}
        fixed={!dimensions?.oversized}
        ref={mobileNavbarRef}
      >
        <MobileMMStickyItems>
          <MobileMMLogoWrapper href={getUrl(settings?.content?.logo_url)}>
            <img src={sbImageService(settings?.content?.logo?.filename)} />
          </MobileMMLogoWrapper>
          <MobileMMOpenCloseIcon isOpen={isMobileOpen} onClick={() => toggleMobileMenu()} />
        </MobileMMStickyItems>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          ref={swiperRef}
          styles={{ marginBottom: "68px" }}
          allowTouchMove={false}
        >
          <SwiperSlide>
            {isMobileOpen && (
              <MobileMMSwiperSlide>
                {settings?.content?.navItems.map((navItem, idx) => (
                  <StyledSubItem onClick={() => handleNavItemClick(idx)} key={navItem._uid}>
                    <StyledSubItemContentWrapper>
                      <MMLabel>{navItem.label}</MMLabel>
                      <StyledSubItemArrowWrapper />
                    </StyledSubItemContentWrapper>
                  </StyledSubItem>
                ))}
              </MobileMMSwiperSlide>
            )}
          </SwiperSlide>
          <SwiperSlide>
            {activeNavItem && (
              <MobileMMSwiperSlide>
                <MobileMMBackButtonWrapper onClick={() => handleBackClick(1)}>
                  {settings?.content?.backToHomeText}
                </MobileMMBackButtonWrapper>
                <MobileMMTitle>{activeNavItem.label}</MobileMMTitle>
                {activeNavItem.subItems?.map((subItem, idx) => (
                  <StyledSubItem onClick={() => handleSubItemClick(idx)} key={subItem._uid}>
                    <StyledSubItemContentWrapper>
                      <MMLabel onClick={() => subItem.isLink && trackClick(subItem.label)}>{subItem.label}</MMLabel>
                      {!subItem.isLink && <StyledSubItemArrowWrapper />}
                    </StyledSubItemContentWrapper>
                  </StyledSubItem>
                ))}
              </MobileMMSwiperSlide>
            )}
          </SwiperSlide>
          <SwiperSlide>
            {activeNavItem && activeSubItem && (
              <MobileMMSwiperSlide>
                <MobileMMBackButtonWrapper onClick={() => handleBackClick(2)}>
                  {settings?.content?.backButtonText}
                </MobileMMBackButtonWrapper>
                <MobileMMTitle>{activeSubItem.label}</MobileMMTitle>
                {activeSubItem.listItems.map((listItem, idx) => (
                  <StyledSubItem onClick={() => handleSubItemClick(idx)} key={listItem._uid}>
                    <StyledSubItemContentWrapper>
                      <MMLabel href={getUrl(listItem.link)} onClick={() => trackClick(listItem.label)}>
                        {listItem.label}
                      </MMLabel>
                    </StyledSubItemContentWrapper>
                  </StyledSubItem>
                ))}
              </MobileMMSwiperSlide>
            )}
          </SwiperSlide>
        </Swiper>
        {isMobileOpen && (
          <MobileMMBottomInfoWrapper>
            <MobileButtonContainer>
              <ButtonHEF
                as="a"
                variant="primary"
                label={settings?.content?.contactLabel}
                href={getUrl(settings?.content?.contactLink)}
              />
            </MobileButtonContainer>
            <MobileMMContactNumberWrapper href={`tel:${settings?.content?.contactNumber}`}>
              <MegaMenuPhoneIcon />
              <MegaMenuContactNumber>{settings?.content?.contactNumber}</MegaMenuContactNumber>
            </MobileMMContactNumberWrapper>
            <MegaMenuLanguageWrapper rows={numberOfRows()}>
              <LanguageSelector />
            </MegaMenuLanguageWrapper>
          </MobileMMBottomInfoWrapper>
        )}
      </MobileMMWrapper>
    </>
  );
};
